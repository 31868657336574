<template>
  <main>
    <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
    <page-header-compact class="mb-2">Historia Clinica - Primera Infancia e Infancia</page-header-compact>
    <div class="container-fluid px-4">
      <div class="card mb-2">
        <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
      </div>
      <!-- <wizard
        :steps="steps.length"
        @prev="prev($event)"
        @next="next($event)"
        :current_step="current"
      >
        <template #tabs>
          <wizard-tab
            title="Motivo Consulta"
            step="1"
            active="true"
            id="step1"
            v-on:tab-click="next({ prev: current, current: 1 })"
          ></wizard-tab>
          <wizard-tab
            title="Examen Fisico"
            step="2"
            id="step2"
            v-on:tab-click="next({ prev: current, current: 2 })"
          ></wizard-tab>
          <wizard-tab
              title="Revisión por sistemas"
              step="3"
              id="step3"
              v-on:tab-click="next({ prev: current, current: 3 })"
          ></wizard-tab>
          <wizard-tab
            title="Graficas"
            step="4"
            id="step4"
            v-on:tab-click="next({ prev: current, current: 4 })"
          ></wizard-tab>
          <wizard-tab
              title="Vacunas"
              step="5"
              id="step5"
              v-on:tab-click="next({ prev: current, current: 5 })"
          >
          </wizard-tab>
          <wizard-tab
            title="Control"
            step="6"
            id="step6"
            v-on:tab-click="next({ prev: current, current: 6 })"
          ></wizard-tab>
          <wizard-tab
            title="lactancia materna"
            step="7"
            id="step7"
            v-on:tab-click="next({ prev: current, current: 7 })"
          ></wizard-tab>
          <wizard-tab
            title="Ordenes"
            step="8"
            id="step8"
            v-on:tab-click="next({ prev: current, current: 8 })"
          ></wizard-tab>
          <wizard-tab
            title="Formulación"
            step="9"
            id="step9"
            v-on:tab-click="next({ prev: current, current: 9 })"
          ></wizard-tab>
          <wizard-tab
            title="Impresión Diagnostica"
            step="10"
            id="step10"
            v-on:tab-click="next({ prev: current, current: 10 })"
          ></wizard-tab>
          <wizard-tab
              title="Plan de Intervención"
              step="11"
              id="step11"
              v-on:tab-click="next({ prev: current, current: 11 })"
          ></wizard-tab>
          <wizard-tab
              title="Recomendaciones"
              step="12"
              id="step12"
              v-on:tab-click="next({ prev: current, current: 12 })"
          ></wizard-tab>
          <wizard-tab
            title="Resumen"
            step="13"
            id="step13"
            v-on:tab-click="next({ prev: current, current: 13 })"
          ></wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                :is="currentComponent"
                :ref="currentComponent"
                v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component :idHistoria="idHistoria"></remision-component>
            <finalizar-historia :id-usuario="usuario.id" :id-historia="idHistoria"></finalizar-historia>
          </div>
        </template>
      </wizard> -->
      <wizard
          :steps="steps.length"
          @prev="prev($event)"
          @next="next($event)"
          :current_step="current"
      >
        <template #tabs>
          <wizard-tab
              v-for="(component, i) in steps"
              :key="`tabs_${i}`"
              :title="component.title"
              :step="i+1"
              :active="(i+1)===current"
              :id="`step${i+1}`"
              v-on:tab-click="next({ prev: current, current: (i+1) })"
          >
          </wizard-tab>
        </template>
        <template #tab-content>
          <wizard-tab-pane id="step1" active="true">
            <template #content>
              <component
                  :is="currentComponent.ref"
                  :ref="currentComponent.ref"
                  v-bind="currentProperties"
              ></component>
            </template>
          </wizard-tab-pane>
        </template>
        <template #finish>
          <div class="row">
            <remision-component :idHistoria="idHistoria" :usuario="usuario"></remision-component>
            <finalizar-historia :id-usuario="usuario.id" :id-historia="idHistoria" :codigoHistoria="'INFANCIA'"></finalizar-historia>
          </div>
        </template>
      </wizard>
    </div>
  </main>
</template>
<script>

import Wizard from "../../../components/common/utilities/Wizard/Wizard";
import WizardTab from "../../../components/common/utilities/Wizard/WizardTab";
import WizardTabPane from "../../../components/common/utilities/Wizard/WizardTabPane";
import motivoConsultaComponent from "../base/motivoConsulta/motivoConsulta";
import examenFisicoComponent from "../base/examenFisico/examenFisico";
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import historiaClinicaService from "../../../services/historiaClinicaService";
import ordenComponent from "../base/orden/ordenComponent";
import formulaMedicaComponent from "../base/formulaMedica/formulaMedicaComponent";
import impresionDXComponent from "../base/impresionDX/impresionDXComponent";
import modalInformacionUsuario from "../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../base/informacionUsuario/cardInformacionUsuario";
import resumenHcComponent from "./resumenHc/resumenHcComponent";
import controlComponent from "./controlComponent";
import graficasComponent from "./graficas";
import indexVacunas from "@/views/historias/base/baseVacunas/index/indexVacunas";
import revisionSistemas from "@/views/historias/base/baseUrgencia/revision";
import intervenciones from "@/views/historias/base/intervenciones/intervenciones";
import recomendaciones from "@/views/historias/base/recomendaciones/recomendaciones";
import FinalizarHistoria from "@/views/historias/base/finalizarHistoria/finalizarHistoria";
import RemisionComponent from "../base/baseRemision/index";
import lmeComponent from "../base/baseRespuestaFormularios/index";
import dayjs from "dayjs";
export default {
  components: {
    FinalizarHistoria,
    PageHeaderCompact,
    motivoConsultaComponent,
    examenFisicoComponent,
    WizardTabPane,
    WizardTab,
    Wizard,
    ordenComponent,
    formulaMedicaComponent,
    impresionDXComponent,
    modalInformacionUsuario,
    cardInformacionUsuario,
    resumenHcComponent,
    controlComponent,
    graficasComponent,
    indexVacunas,
    revisionSistemas,
    intervenciones,
    recomendaciones,
    RemisionComponent,
    lmeComponent
  },
  data() {
    return {
      finalizada: "",
      idHistoria: "",
      usuario: {},
      current: 1,
      idTipoHistoria : '',
      checkpoint : 1,
      /* steps: [
        { stepId: "#step1", ref: "motivoConsultaComponent", validate: true },
        { stepId: "#step2", ref: "examenFisicoComponent", validate: true },
        { stepId: "#step3", ref: "revisionSistemas", validate: true },
        { stepId: "#step4", ref: "graficasComponent", validate: false },
        { stepId: "#step5", ref: "indexVacunas", validate: false },
        { stepId: "#step6", ref: "controlComponent", validate: false },
        { stepId: "#step7", ref: "lmeComponent", validate: true },
        { stepId: "#step8", ref: "ordenComponent", validate: false },
        { stepId: "#step9", ref: "formulaMedicaComponent", validate: false },
        { stepId: "#step10", ref: "impresionDXComponent", validate: true },
        { stepId: "#step11", ref: "intervenciones", validate: false },
        { stepId: "#step12", ref: "recomendaciones", validate: false },
        { stepId: "#step13", ref: "resumenHcComponent", validate: false },
      ], */
    };
  },
  methods: {
    prev(step) {

      this.current = step.current;
      this.activeTab();
      
    },
    async next(step) {
      
      if(step.prev <= this.checkpoint && step.current-1 > step.prev){
        this.activeTab();
        return;
      }
      
      const refComponent = this.steps[step.prev - 1].ref;
      
      if (typeof this.$refs[refComponent].save !== "undefined") {
        await this.$refs[refComponent].save();
      }

      if (this.steps[step.prev - 1].validate) {
        if (this.$refs[refComponent].$v.$invalid) return;
      }

      if(step.current > this.checkpoint) {
        historiaClinicaService.updateCheckpoint(this.idHistoria, step.current);
        this.checkpoint = step.current;
      }

      this.current = step.current;

      this.activeTab();
    },
    async cargar(id) {
      
      const response = await historiaClinicaService.show(id);
      
      this.usuario = response.data.usuario;
      this.finalizada = response.data.finalizada;
      this.current = response.data.checkpoint;
      this.checkpoint = response.data.checkpoint;
      this.idTipoHistoria = response.data.id_tipo_historia;

      this.activeTab();
    },
    activeTab(){
      document.querySelectorAll(`a.active`).forEach(a =>{ a.classList.remove('active') });
      document.querySelector(`a[href='${this.steps[this.current - 1].stepId}']`).classList.add("active");
    }
  },
  created() {
    this.idHistoria = this.$route.params.id;
    this.cargar(this.idHistoria);
  },
  computed: {
    currentProperties() {

      if(this.currentComponent.ref === 'lmeComponent') {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
          usuario : this.usuario,
          codigo:'LME'
        }
      }

      if (this.currentComponent.ref === "motivoConsultaComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      if (this.currentComponent.ref === "examenFisicoComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          modo : 'INFANCIA'
        }
      }
      if (this.currentComponent.ref === "graficasComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario
        }
      }
      if (this.currentComponent.ref === "controlComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          idUsuario:this.usuario.id,
          usuario : this.usuario,
          idTipoHistoria:this.idTipoHistoria,
        }
      }
      
      if (this.currentComponent.ref === "ordenComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario
        }
      }
      if (this.currentComponent.ref === "formulaMedicaComponent") {
        return {
          idHistoria: this.idHistoria,
          usuario : this.usuario,
        }
      }
      if (this.currentComponent.ref === "impresionDXComponent") {
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }
      if (this.currentComponent.ref === "resumenHcComponent") {
        return {
          idHistoria: this.idHistoria,
          idUsuario:this.usuario.id,
          usuario : this.usuario
        }
      }
      if(this.currentComponent.ref === "indexVacunas"){
        return {
          idHistoria: this.idHistoria,
          idUsuario:this.usuario.id,
          finalizada: this.finalizada,
        }
      }

      if(this.currentComponent.ref === "revisionSistemas"){
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
        }
      }

      if(this.currentComponent.ref === "intervenciones"){
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario : this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria
        }
      }

      if(this.currentComponent.ref === "recomendaciones"){
        return {
          idHistoria: this.idHistoria,
          finalizada: this.finalizada,
          usuario : this.usuario.sexo,
          idTipoHistoria: this.idTipoHistoria
        }
      }

      return {}
    },
   /*  currentComponent() {

      let components = [
        "motivoConsultaComponent",
        "examenFisicoComponent",
        "revisionSistemas",
        "graficasComponent",
        "indexVacunas",
        "controlComponent",
        "lmeComponent",
        "ordenComponent",
        "formulaMedicaComponent",
        "impresionDXComponent",
        "intervenciones",
        "recomendaciones",
        "resumenHcComponent"
      ];

      return components[this.current - 1];
    }, */
    currentComponent() {
      return this.steps[this.current - 1];
    },
    steps(){
     
      let components = [
        { ref: "motivoConsultaComponent", title:"Motivo de Consulta",validate: true },
        { ref: "examenFisicoComponent", title:"Examen Físico", validate: true },
        { ref: "revisionSistemas", title:"Revision por Sistemas",validate: true },
        { ref: "graficasComponent", title:"Graficas",validate: false },
        { ref: "indexVacunas", title:"Vacunas",validate: false },
        { ref: "controlComponent",title:"Control",validate: false },
        { ref: "ordenComponent", title:"Ordenes",validate: false },
        { ref: "formulaMedicaComponent",title:"Formulas",validate: false },
        { ref: "impresionDXComponent",title:"Impresión DX",validate: true },
        { ref: "intervenciones",title:"Plan de Intervención",validate: false },
        { ref: "recomendaciones",title:"Recomendaciones",validate: false },
      ];

      if(this.edad >= 0 && this.edad <= 180){
        components.push({ ref: "lmeComponent", title:"Lactancia Materna",validate: true });
        components.push({ ref: "resumenHcComponent", title:"Resumen",validate: true });
      }else{
        components.push({ ref: "resumenHcComponent", title:"Resumen",validate: true });
      }

      return components.map((x,i) => {
        return {
          stepId : `#step${i+1}`,
          ...x,
        }
      });
      },
      edad: function() {
        const now = dayjs();
        console.log(now.diff(this.usuario.fecha_nacimiento, "day"));
        return now.diff(this.usuario.fecha_nacimiento, "day");

      },
  },
}
</script>

<style scoped></style>
