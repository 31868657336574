import Service from "./Service";
const baseurl = '/api/valoracion-apgar-familiar';
export default {
    index() {
        return Service.get(`${baseurl}`);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showLast(id_usuario) {
        return Service.get(`${baseurl}/show-last/${id_usuario}`);
    },
    showAll(id_usuario) {
        return Service.get(`${baseurl}/show-all/${id_usuario}`);
    },

    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    delete(id) {
        return Service.delete(`${baseurl}/delete/${id}`);
    },
}