<template>
  <main>
    <div class="row">
      <div class="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-sm-12 col-xs-12" v-for="(chart, i) in apply" :key="`chart_${i}`" >
        <component :ref="chart.component" :is="chart.component" v-bind="chartsData[chart.component]"></component>
      </div>
    </div>
  </main>
</template>
<script>
import PesoParaLaEdadNinas0a2A from "../base/graficas/pesoParaLaEdadNinas0a2A/pesoParaLaEdadNinas0a2A";
import pesoParaLaEdadNinas2a5A from "../base/graficas/pesoParaLaEdadNinas2a5A/pesoParaLaEdadNinas2a5A";
import pesoParaLaEdadNinos0a2A from "../base/graficas/pesoParaLaEdadNinos0a2A/pesoParaLaEdadNinos0a2A";
import pesoParaLaEdadNinos2a5A from "../base/graficas/pesoParaLaEdadNinos2a5A/pesoParaLaEdadNinos2a5A";
import pesoParaLaTallaNinas0a2A from "../base/graficas/pesoParaLaTallaNinas0a2A/pesoParaLaTallaNinas0a2A";
import pesoParaLaTallaNinas2a5A from "../base/graficas/pesoParaLaTallaNinas2a5A/pesoParaLaTallaNinas2a5A";
import pesoParaLaTallaNinos0a2A from "../base/graficas/pesoParaLaTallaNinos0a2A/pesoParaLaTallaNinos0a2A";
import pesoParaLaTallaNinos2a5A from "../base/graficas/pesoParaLaTallaNinos2a5A/pesoParaLaTallaNinos2a5A";
import perimetroCefalicoNinos from "../base/graficas/perimetroCefalicoNinos/perimetroCefalicoNinos";
import perimetroCefalicoNinas from "../base/graficas/perimetroCefalicoNinas/perimetroCefalicoNinas";
import imcNinos0a2A from "../base/graficas/imcNinos0a2A/imcNinos0a2A";
import imcNinos2a5A from "../base/graficas/imcNinos2a5A/imcNinos2a5A";
import imcNinas0a2A from "../base/graficas/imcNinas0a2A/imcNinas0a2A";
import imcNinas2a5A from "../base/graficas/imcNinas2a5A/imcNinas2a5A";
import tallaParaLaEdadNinos0a2A from "../base/graficas/tallaParaLaEdadNinos0a2A/tallaParaLaEdadNinos0a2A";
import tallaParaLaEdadNinos2a5A from "../base/graficas/tallaParaLaEdadNinos2a5A/tallaParaLaEdadNinos2a5A";
import tallaParaLaEdadNinas0a2A from "../base/graficas/tallaParaLaEdadNinas0a2A/tallaParaLaEdadNinas0a2A";
import tallaParaLaEdadNinas2a5A from "../base/graficas/tallaParaLaEdadNinas2a5A/tallaParaLaEdadNinas2a5A";
import dayjs from "dayjs";
import examenFisicoService from '../../../services/examenFisicoService';
import {isEmpty} from "lodash";
import graficasService from "@/services/graficasService";
import Toast from '@/components/common/utilities/toast';
export default {
  props: ["usuario", "idHistoria"],
  components: {
    PesoParaLaEdadNinas0a2A,
    pesoParaLaEdadNinas2a5A,
    pesoParaLaEdadNinos0a2A,
    pesoParaLaEdadNinos2a5A,
    pesoParaLaTallaNinas0a2A,
    pesoParaLaTallaNinas2a5A,
    pesoParaLaTallaNinos2a5A,
    pesoParaLaTallaNinos0a2A,
    perimetroCefalicoNinos,
    perimetroCefalicoNinas,
    imcNinos0a2A,
    imcNinos2a5A,
    imcNinas0a2A,
    imcNinas2a5A,
    tallaParaLaEdadNinos0a2A,
    tallaParaLaEdadNinos2a5A,
    tallaParaLaEdadNinas0a2A,
    tallaParaLaEdadNinas2a5A,
  },
  data (){
    return {
      examenFisico : {},
      charts : [
        {
          name : 'Peso para la Edad Niñas de 0 a 2 Años',
          component : 'PesoParaLaEdadNinas0a2A',
          start : 0,
          end : 2,
          gender : 'F'
        },
        {
          name : 'Peso para la Edad Niñas de 2 a 5 Años',
          component : 'pesoParaLaEdadNinas2a5A',
          start : 2,
          end : 5,
          gender : 'F'
        },
        {
          name : 'Peso para la Edad Niños de 0 a 2 Años',
          component : 'pesoParaLaEdadNinos0a2A',
          start : 0,
          end : 2,
          gender : 'M'
        },
        {
          name : 'Peso para la Edad Niños de 2 a 5 Años',
          component : 'pesoParaLaEdadNinos2a5A',
          start : 2,
          end : 5,
          gender : 'M'
        },
        {
          name : 'Peso para la Talla Niñas de 0 a 2 Años',
          component : 'pesoParaLaTallaNinas0a2A',
          start : 0,
          end : 2,
          gender : 'F'
        },
        {
          name : 'Peso para la Talla Niñas de 2 a 5 años',
          component : 'pesoParaLaTallaNinas2a5A',
          start : 2,
          end : 5,
          gender : 'F'
        },
        {
          name : 'Peso para la Talla Niños de 2 a 5 Años',
          component : 'pesoParaLaTallaNinos2a5A',
          start : 2,
          end : 5,
          gender : 'M'
        },
        {
          name : 'Peso para la Talla Niños de 0 a 2 Años',
          component : 'pesoParaLaTallaNinos0a2A',
          start : 0,
          end : 2,
          gender : 'M'
        },
        {
          name : 'Perimetro Cefalico Niños',
          component : 'perimetroCefalicoNinos',
          start : 0,
          end : 5,
          gender : 'M'
        },
        {
          name : 'Perimetro Cefalico Niñas',
          component : 'perimetroCefalicoNinas',
          start : 0,
          end : 5,
          gender : 'F'
        },
        {
          name : 'IMC para la Edad Niños de 0 a 2 Años',
          component : 'imcNinos0a2A',
          start : 0,
          end : 2,
          gender : 'M'
        },
        {
          name : 'IMC para la Edad Niños de 2 a 5 Años',
          component : 'imcNinos2a5A',
          start : 2,
          end : 5,
          gender : 'M'
        },
        {
          name : 'IMC para la Edad Niñas de 0 a 2 Años',
          component : 'imcNinas0a2A',
          start : 0,
          end : 2,
          gender : 'F'
        },
        {
          name : 'IMC para la Edad Niñas de 2 a 5 Años',
          component : 'imcNinas2a5A',
          start : 2,
          end : 5,
          gender : 'F'
        },
        {
          name : 'Talla para la Edad Niños de 0 a 2 Años',
          component : 'tallaParaLaEdadNinos0a2A',
          start : 0,
          end : 2,
          gender : 'M'
        },
        {
          name : 'Talla para la Edad Niños de 2 a 5 Años',
          component : 'tallaParaLaEdadNinos2a5A',
          start : 2,
          end : 5,
          gender : 'M'
        },
        {
          name : 'Talla para la Edad Niñas de 0 a 2 Años',
          component : 'tallaParaLaEdadNinas0a2A',
          start : 0,
          end : 2,
          gender : 'F'
        },
        {
          name : 'Talla para la Edad Niñas de 2 a 5 Años',
          component : 'tallaParaLaEdadNinas2a5A',
          start : 2,
          end : 5,
          gender : 'F'
        },
      ],
    }
  },
  async created(){
    this.LoaderSpinnerShow();
    const response = await examenFisicoService.showByHc(this.idHistoria);
    this.examenFisico = response.data;
    this.LoaderSpinnerHide();
  },  
  methods : {
    async save(){
      this.LoaderSpinnerShow();
      let charts = [];

      this.apply.forEach(x => {
        charts.push(this.$refs[x.component][0].export());
      });

      const promiseArray = charts.map(x => {
        return graficasService.store({
          id_historia : this.idHistoria,
          charts : [x]
        });
      });
      
      await Promise.all(promiseArray);

      /* await graficasService.store({
        id_historia : this.idHistoria,
        charts : charts
      }); */
      this.LoaderSpinnerHide();
      Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
      });
    }
  },
  computed: {
    edad: function() {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
    edadEnMeses: function() {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "month", true);
    },
    indice_masa_corporal (){

      if(isEmpty(this.examenFisico)) return 0;

      if(this.peso !== '' && this.talla !== ''){
        let imc = Number(this.examenFisico.peso) / (Math.pow(Number(this.examenFisico.talla/100), 2) );
        return Number(imc.toFixed(2));
      }

      return 0;
    },
    apply(){
      if(isEmpty(this.examenFisico)) return [];
      return this.charts.filter(x => this.edad >= x.start && this.edad <= x.end && x.gender === this.usuario.sexo);
    },
    chartsData(){
      return {
        'PesoParaLaEdadNinas0a2A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.peso
        },
        'pesoParaLaEdadNinas2a5A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.peso
        },
        'pesoParaLaEdadNinos0a2A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.peso
        },
        'pesoParaLaEdadNinos2a5A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.peso
        },
        'pesoParaLaTallaNinas0a2A' : {
          x : this.examenFisico.talla,
          y : this.examenFisico.peso
        },
        'pesoParaLaTallaNinas2a5A' : {
          x : this.examenFisico.talla,
          y : this.examenFisico.peso
        },
        'pesoParaLaTallaNinos2a5A' : {
          x : this.examenFisico.talla,
          y : this.examenFisico.peso
        },
        'pesoParaLaTallaNinos0a2A' : {
          x : this.examenFisico.talla,
          y : this.examenFisico.peso
        },
        'perimetroCefalicoNinos' : {
          x : this.edadEnMeses,
          y : this.examenFisico.perimetro_cefalico
        },
        'perimetroCefalicoNinas' : {
          x : this.edadEnMeses,
          y : this.examenFisico.perimetro_cefalico
        },
        'imcNinos0a2A' : {
          x : this.edadEnMeses,
          y : this.indice_masa_corporal
        },
        'imcNinos2a5A' : {
          x : this.edadEnMeses,
          y : this.indice_masa_corporal
        },
        'imcNinas0a2A' : {
          x : this.edadEnMeses,
          y : this.indice_masa_corporal
        },
        'imcNinas2a5A' : {
          x : this.edadEnMeses,
          y : this.indice_masa_corporal
        },
        'tallaParaLaEdadNinos0a2A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.talla
        },
        'tallaParaLaEdadNinos2a5A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.talla
        },
        'tallaParaLaEdadNinas0a2A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.talla
        },
        'tallaParaLaEdadNinas2a5A' : {
          x : this.edadEnMeses,
          y : this.examenFisico.talla
        },
      }
    }
  },
};
</script>
