<template>
    <main>
        <div class="card shadow-none border-0">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> REVISIÓN POR SISTEMAS</span> </div>
            <div class="card-body">
                <p>{{ urgenciaRevision.revision }}</p>
            </div>
        </div>
    </main>
</template>
<script>
import urgenciaRevisionService from "../../../../services/urgenciaRevisionService";
import { isEmpty } from 'lodash';
export default {
    props: ['idHistoria', 'finalizada'],
    data() {
        return {
            id_historia: '',
            urgenciaRevision: {},
        }
    },
    async created() {
        const response = await urgenciaRevisionService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
            this.urgenciaRevision = response.data;
        }
    }

}
</script>

