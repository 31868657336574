<template>
  <main>
    <div class="row pt-2">
      <div class="col-12">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item" v-for="(item, i) in dimensiones" :key="item.id">
            <dimension role="tab" data-toggle="tab" :class="{ active: i == 0 }" :href="`#tab_${i}`" :dimension="item">
            </dimension>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div v-for="(item, i) in dimensiones" class="tab-pane fade" :class="{ 'show active': i === 0 }" :id="`tab_${i}`"
            role="tabpanel" :key="`tab_${item.id}`">
            <div class="table-responsive">
              <div v-for="(rango, i) in item.rangos" :key="`${rango.id}${i}${item.id}`" class="mt-2">
                <table v-if="rango.id <= rango_actual.id" class="
                    table table-striped table-sm table-bordered
                    font-size-12
                  ">
                  <tbody>
                    <tr v-for="(enunciado, i) in rango.enunciados" :key="`${enunciado.id}`">
                      <td v-if="i === 0" class="text-center align-middle w-25" :rowspan="rango.enunciados.length">
                        {{ rango.nombre }}
                      </td>
                      <td class="w-10 align-middle text-center">
                        {{ enunciado.orden }}
                      </td>
                      <td class="w-50 align-middle text-center">
                        {{ enunciado.enunciado }}
                      </td>
                      <td class="w-25 align-middle text-center">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="`enum_${enunciado.id}`" :id="enunciado.id"
                            value="1" v-model="enunciado.respuesta" />
                          <label class="form-check-label">1</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" :name="`enum_${enunciado.id}`" :id="enunciado.id"
                            value="0" v-model="enunciado.respuesta" />
                          <label class="form-check-label">0</label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div class="form-group row">
              <label class="col-sm-10 col-form-label text-right text-primary">Total acumulados al inicio</label>
              <div class="col-sm-2">
                <input type="number" readonly class="form-control" v-model="item.total_acumulado_inicio" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-10 col-form-label text-right text-primary">Número de items correctos</label>
              <div class="col-sm-2">
                <input type="number" class="form-control" v-model="item.total_items_correctos" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-10 col-form-label text-right text-primary">Total (PD)</label>
              <div class="col-sm-2">
                <input type="number" class="form-control" :value="item.total_items_correctos + item.total_acumulado_inicio
                  " />
              </div>
            </div>
            <button class="btn btn-success" @click="guardar()">
              <i class="fas fa-save"></i>&nbsp;Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Toast from '../../../../../components/common/utilities/toast';
import dimensionesEscalaAbreviadaDesarrolloService from "../../../../../services/dimensionesEscalaAbreviadaDesarrolloService";
import evaluacionEscalaAbreviadaDesarrolloService from "../../../../../services/evaluacionEscalaAbreviadaDesarrolloService";
import dimension from "./dimension";
import { isEmpty } from 'lodash';
import rangoEscalaAbreviadaDesarrolloService from "../../../../../services/rangoEscalaAbreviadaDesarrolloService";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  components: { dimension },
  data() {
    return {
      id_usuario: "",
      id_historia: "",
      id_historia_evaluacion: '',
      dimensiones: [],
      rango_actual: {},
      has_evaluacion: false,
    };
  },
  methods: {
    async cargarDimensiones() {
      const response = await dimensionesEscalaAbreviadaDesarrolloService.index(
        {
          fecha_nacimiento: this.usuario.fecha_nacimiento
        }
      );

      let dimensiones = response.data;

      const respuesta_historia = await evaluacionEscalaAbreviadaDesarrolloService.showByHc(
        this.idHistoria
      );

      if (!isEmpty(respuesta_historia.data)) {
        this.has_evaluacion = true;
      }

      let rangos = {};

      dimensiones.forEach((x) => {
        x.enunciados.forEach((y) => {
          if (rangos[y.rango.id] === undefined) {
            rangos[y.rango.id] = {
              ...y.rango,
            };
          }
        });
      });

      dimensiones.forEach((x, i) => {
        let enunciados = [];

        let dimension_respuesta = {};

        if (!isEmpty(respuesta_historia.data)) {
          dimension_respuesta = respuesta_historia.data.find(
            (resHistoria) => resHistoria.id_dimension === x.id
          );
        }

        for (const rango in rangos) {
          enunciados.push({
            ...rangos[rango],
            enunciados: x.enunciados
              .filter((e) => e.id_rango === parseInt(rango))
              .map((en) => {

                let respuesta = "";

                if (!isEmpty(dimension_respuesta)) {

                  const hasRespuesta = dimension_respuesta.respuesta.find(
                    (res) => parseInt(res.id_enunciado) === parseInt(en.id)
                  );

                  if (hasRespuesta !== undefined) {
                    respuesta = parseInt(hasRespuesta.respuesta)
                  }
                }

                return {
                  ...en,
                  respuesta: respuesta,
                };
              }),
          });
        }

        dimensiones[i]["rangos"] = enunciados;

        dimensiones[i]["total_items_correctos"] = 0;
        dimensiones[i]["total_acumulado_inicio"] = dimensiones[i][
          "rangos"
        ].reduce((total, rango) => {
          if (rango.orden < this.rango_actual.orden) {
            return total + rango.enunciados.length;
          }

          return total;
        }, 0);

        enunciados = [];
      });

      this.dimensiones = dimensiones;
    },
    async guardar() {
      try {
        let respuestas_es = [];

        this.dimensiones.forEach((x) => {
          let dimension = {
            id_dimension: x.id,
            respuestas: [],
            puntuacion_directa:
              x.total_acumulado_inicio + x.total_items_correctos,
            puntuacion_tipica: 20,
          };

          x.rangos.forEach((y) => {
            const respuesta = y.enunciados.map((e) => {
              return {
                id_enunciado: e.id,
                respuesta: e.respuesta,
              };
            });

            dimension.respuestas.push(...respuesta);
          });

          respuestas_es.push(dimension);
        });

        let evaluacion = {
          id_historia: this.idHistoria,
          id_usuario: this.idUsuario,
          dimensiones: respuestas_es,
        };



        this.LoaderSpinnerShow();
        if (this.has_evaluacion) {
          await evaluacionEscalaAbreviadaDesarrolloService.update(evaluacion);
        } else {
          await evaluacionEscalaAbreviadaDesarrolloService.store(evaluacion);
          this.has_evaluacion = true;
        }

        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un error al procesar la solicitud'
        });
      }
    }
  },
  async created() {

    const res = await rangoEscalaAbreviadaDesarrolloService.showByBirthday(
      this.usuario.fecha_nacimiento
    );
    this.rango_actual = res.data;

    await this.cargarDimensiones();
  },
  watch: {
    dimensiones: {
      handler: function () {
        this.dimensiones.forEach((x, i) => {
          let total_items_correctos = x.rangos.reduce((total, rango) => {
            const total_rango = rango.enunciados.reduce(
              (total_rango, enunciado) => {
                return (
                  total_rango +
                  (enunciado.respuesta !== "" ? Number(enunciado.respuesta) : 0)
                );
              },
              0
            );

            return total + total_rango;
          }, 0);

          this.dimensiones[i]["total_items_correctos"] = total_items_correctos;
        });
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>
