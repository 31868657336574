import { render, staticRenderFns } from "./pesoParaLaEdadNinos2a5A.vue?vue&type=template&id=1bd79f70&scoped=true&"
import script from "./pesoParaLaEdadNinos2a5A.vue?vue&type=script&lang=js&"
export * from "./pesoParaLaEdadNinos2a5A.vue?vue&type=script&lang=js&"
import style0 from "./pesoParaLaEdadNinos2a5A.vue?vue&type=style&index=0&id=1bd79f70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd79f70",
  null
  
)

export default component.exports