<template>
    <main>
        <div class=" mb-2" v-for="(item,index) in formulario" :key="index">
            <div  class="card-header py-2 d-flex justify-content-between align-items-center">
                {{item.nombre}}
            </div>
            <div class="card-body">
                <div class="row" v-for="(pregunta, index) in item.preguntas" :key="`pregunta_${index}`">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label class="form-label text-black">{{index+1}} - {{pregunta.nombre}}</label>
                        <!-- <select name="" id="" class="form-control form-control-sm" v-model="pregunta.respuesta" >
                            <option :value="opc.opcion" v-for="opc in pregunta.opciones" :key="opc.id">{{opc.texto}}</option>
                        </select> -->
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="form-check form-check-inline" v-for="opc in pregunta.opciones" :key="opc.id">
                            <input 
                                class="form-check-input"
                                type="radio"
                                :name="`enum_${opc.id}`"
                                :id="opc.id"
                                :value="opc.texto"
                                v-model="pregunta.respuesta"
                            />
                            <label class="form-check-label">{{opc.texto}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-danger text-center" v-if="$v.formulario.$error && $v.formulario.$dirty">Debe completar el formulario</div> 
    </main>
</template>
<script>
import formulariosService from "../../../../services/formulariosService";
import Toast from '../../../../components/common/utilities/toast';
import respuestasFormulariosService from '../../../../services/respuestasFormulariosService';
import {isEmpty} from "lodash";
import {required} from "vuelidate/lib/validators";

export default {
    props: ['idHistoria','finalizada','codigo','trimestre'],
    data() {
        return {
           formulario:{},
           id_formulario : '',         
        }
    },
    validations (){
        return {
            formulario : {
                $each : {
                    preguntas:{
                        $each : {
                            respuesta: {required}
                        }
                    }
                }
            }
        }
    },
    methods: {
        async cargarFormulario(){

            try {

                this.LoaderSpinnerShow();

                const response = await formulariosService.showByCode(this.codigo);
                this.id_formulario = response.data.id;

                const respuestas_formulario=await respuestasFormulariosService.showByRespuestableId(
                    {
                        id:this.idHistoria,
                        id_formulario:this.id_formulario,
                        trimestre:this.trimestre!==''?this.trimestre:'',
                    }
                );
                

                this.formulario=response.data.secciones.map(x=>{
                    
                    return {
                        id:x.id,
                        nombre:x.nombre,
                        preguntas:x.preguntas.map(y=>{
                            let respuesta='';
                            if(!isEmpty(respuestas_formulario.data)){
                                respuesta=respuestas_formulario.data.items.find(r=>r.id_pregunta===y.id)?.valor || ''
                            }
                            return {
                                ...y,
                                respuesta:respuesta,
                            }
                        })
                    }
                });

                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
            
        },
        async save() {
            try{

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let respuestas=[];
                
                this.formulario.forEach(x=>{

                    x.preguntas.reduce((t,c) => {
                        let opcion = c.opciones.find(opc=>opc.opcion===c.respuesta);
                        return opcion!==undefined?opcion.id:0;
                    },0); 

                    let respuestas_secciones=x.preguntas.map(y=>{
                        let opcion=y.opciones.find(opc=>opc.texto===y.respuesta);
                        return {
                            id_pregunta:y.id,
                            respuesta:y.respuesta,
                            id_opcion:opcion!==undefined?opcion.id:'',
                        }
                    })

                    respuestas.push(...respuestas_secciones);
                });

                let respuestas_historia={
                    id_historia: this.idHistoria,
                    id_formulario:this.id_formulario,
                    respuestas:respuestas,
                    trimestre:this.trimestre
                }

                console.log(respuestas_historia);
        
                this.LoaderSpinnerShow();
                
                await respuestasFormulariosService.store(respuestas_historia);
            
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            }catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }  
        }, 
    },
    async created(){
       await this.cargarFormulario();
    },
    watch: {
        trimestre: async function (value){ 
            if(value){
                this.cargarFormulario();                
            }
        }
    },
    
}
</script>